import React from "react";
import Artykul from "../components/artykul";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import obrazekArtykulu from "../images/Program-CRM.jpeg";

export const query = graphql`
  query {
    zdjecie1: file(
      relativePath: { eq: "Program-CRM-infografika-784x1080.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 784, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(
      relativePath: { eq: "system-crm-Baner-testuj.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 770, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
const DlaczegoProgramCRM = ({ data }) => {
  return (
    <Artykul
      title="11 powodów, dla których warto wdrożyć program CRM w firmie"
      articleImage={obrazekArtykulu}
      keywords={["program CRM"]}
      articleImageAlt="program CRM"
      metaTitle="Program CRM - 11 powodów, dla których warto go wdrożyć."
      metaDescription="Program CRM - dlaczego warto go wdrożyć? Oto 11 powodów. Quick CRM, prosty CRM dla dynamicznych zespołów"
    >
      <p>
        Żaden biznes nie jest tak bliski swoim klientom jak mała firma. Jako właściciel jednej z nich na pewno nie tylko utrzymujesz osobiste więzi z większością swoich kontrahentów, ale także doskonale rozumiesz ich indywidualne potrzeby. Znajomość historii każdego z nich pozwala na dostarczenie spersonalizowanych usług, które sprawiają, że Twoja firma jest wyjątkowa. To właśnie <strong> silne relacje z klientami są kluczowym czynnikiem sukcesu małych przedsiębiorstw. </strong> Program CRM pomoże Ci je nawiązać!
      
      </p>
      
      <br>
      </br>
    <h2>Program CRM a poziom relacji</h2>
      <p>
        Co jeśli mógłbyś optymalizować ten istotny element biznesu przy jednoczesnym poszerzaniu horyzontów? Program CRM może sprawić, że przestanie być to jedynie marzeniem a stanie się rzeczywistością. Może sprawić, że znacznie zwiększysz grono swoich klientów i jednocześnie zachowasz ten sam wysoki poziom relacji z nimi.  Taka inwestycja zwróci się bardzo szybko w postaci <strong> zadowolonych klientów, którzy mają świadomość, że ktoś ich słucha i reaguje na potrzeby w mgnieniu oka.</strong>
        <br>
        </br> 
        </p>
     <br>
     </br>

    <h3>Dlaczego powinieneś zarządzać relacjami z klientami?</h3>  
      
      <p>
      Doświadczenia zakupowe Twoich klientów są głównym czynnikiem napędzającym przychody Twojej firmy. Jej produkty i usługi są z całą pewnością świetne, jednak jakość interakcji z klientami jest równie istotna jak jakość obsługi, jeśli nawet nie ważniejsza! Efektywne zarządzanie relacjami pomaga zapewnić pozytywne doświadczenie wszystkim klientom, a w szczególności tym obecnym. Każda informacja o nich jest wartościową wskazówką. Im więcej masz danych, tym łatwiej możesz odpowiedzieć na indywidulane potrzeby. <strong> To wzmacnia relacje, rodzi lojalność, otwiera nowe możliwości sprzedaży. </strong>
      <br>
      </br>
      <br>
      </br>
      Dzięki usystematyzowanej wiedzy możesz sprzedawać stałym klientom produkty lub usługi uzupełniające. Lepsze trafienie w potrzeby klienta to też większa szansa na polecenia Twojej firmy innym osobom. Zupełnie nowi klienci bez dodatkowych kosztów marketingowych są na wyciągnięcie ręki! Co więcej, dane o kontrahentach pomogą Ci podejmować lepsze decyzje biznesowe. W jaki sposób? Z systemem CRM zyskujesz nowe możliwości!
      </p>
      <br>
      </br>
      <p>
      <ol>
      <li><strong>Rozpoznawaj</strong> bardziej i mniej dochodowych klientów</li>
      <li><strong>Obniżaj</strong> koszty sprzedaży</li>
      <li><strong>Obserwuj</strong> problemy klientów</li>
      <li><strong>Przystosuj</strong> swój biznes do dynamicznie zmieniającego się otoczenia i wymagań klientów</li>
      <li><strong>Dostosowuj</strong> ofertę, spełniając potrzeby każdego klienta</li>
      <li><strong>Trafiaj</strong> z właściwym produktem do właściwych osób</li>
      </ol>
      </p>
      <br>
      </br>
      
      <Img
        style={{
          width: "0 auto",
          margin: "0 auto"
        }}
        title="program CRM dla małej firmy - infografika"
        alt="program CRM dla małej firmy - infografika"
        fluid={data.zdjecie1.childImageSharp.fluid}
      />
      <a href="https://www.pinterest.com/pin/715439090776809333//">Infografika</a>: 11 powodów, dlaczego warto wdrożyć program CRM w firmie
      <p>
      </p>
      <br>
      </br>
      <p>
        W jaki sposób używać zdobytych danych, aby program CRM dawał Twojej firmie korzyści każdego dnia? Raz uzupełnione i uporządkowane dane otworzą Ci wiele nowych sposobów praktycznego wykorzystania narzędzi oferowanych przez program CRM.
        </p>
      <br>
      </br>
      <p>
      <li><strong>Dziel się informacjami ze swoim zespołem</strong> – program CRM udostępnia Wam dostęp uporządkowanych i spójnych danych. Nie chcesz, żeby każdy z członków zespołu widział wszystkie dane? Możesz użyć opcji <a href="/zarzadzanie-uprawnieniami/">uprawnienia</a> i zdecydować kto ma je zobaczyć.</li>
      <li><strong>Skupiaj się na celu</strong> – program CRM z <a href="/kalendarz-i-zadania/">kalendarzem i zadaniami</a> sprawi, że możesz robić to skuteczniej. Pomoże zaplanować spotkania, wydarzenia, listę rzeczy do zrobienia. Możesz także monitorować przypomnienia.</li>
      <li><strong>Zbieraj spostrzeżenia</strong> - <a href="/raporty/">raporty</a> i <a href="/sprzedaz-w-crm/">szanse sprzedaży</a> są nieocenionym źródłem wiedzy o Twoich klientach i procesach sprzedażowych Twojej firmy. Taka wiedza pozwoli wykryć ewentualne słabe strony w procesach sprzedaży albo udoskonalić proces sprzedaży.</li>
      <li><strong>Podnieś jakość interakcji klientów z Twoją firmą</strong> program CRM dostarcza pełny obraz <a href="/kartoteki-kontrahentow/">historii klientów</a>, dlatego masz przewagę w szybkim odpowiadaniu na ich potrzeby.</li>
      <li><strong>Wszystkie dane w każdej chwili</strong> - kompletne informacje o firmie i jej są dostępne z Twojego komputera, tabletu oraz smartfonu, gdziekolwiek masz połączenie z Internetem. Dzięki temu w każdej chwili możesz np. odpowiedzieć klientowi na zapytanie, sprawdzając historię kontaktu oraz dane dotyczące sprzedaży danego produktu.</li>
      </p>
      <br>
      </br>
      <p>
      Kiedy planujesz wdrożenie systemu CRM w swojej firmie, pamiętaj o potrzebach swojej firmy. Prosty program CRM będzie w zupełności wystarczający na początek. Nie zapomnij, że korzyści, które przyniesie program CRM będą bezpośrednio powiązane z tym, czy będziesz efektywnie z niego korzystać. Zaangażuj cały swój zespół we włączanie CRM do życia firmy i czerp pełnymi garściami z jego funkcjonalności.
      </p>
    </Artykul>
    
  );
};

export default DlaczegoProgramCRM;